<template>
	<div class="lactate_text_view">
		<div class="child-top" style="padding-top: 0">
			<div class="input-from">
				<el-date-picker
					v-model="date"
					type="daterange"
					align="right"
					unlink-panels
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:picker-options="pickerOptions"
					@change="create(1)"
					class="picker_date"
				>
				</el-date-picker>
				<el-cascader
					@change="searchCreate"
					style="float: left"
					placeholder="请选择运动队"
					clearable
					v-model="deparmentId"
					:options="departmentOptions"
					:show-all-levels="false"
					collapse-tags
					:props="{ children: 'children', label: 'name', value: 'uuid', emitPath: false, multiple: true }"
				></el-cascader>
				<!-- <div>
					<el-input
						@keyup.enter.native="create(1)"
						@clear="create(1)"
						class="left-child-input left-child-input-content"
						clearable
						placeholder="请输入标题关键字"
						style="width: 200px; float: left"
						suffix-icon="iconfont iconsousuo"
						v-model="inputValue"
					>
					</el-input>
					<el-button class="searchBtn" round @click="create(1)">搜索</el-button>
				</div> -->
			</div>

			<div class="operation-button">
				<!-- <el-button @click="getYLJdata" class="down_btn" operation-button round v-if="tbButtonShow">
					<span>同步易力加数据</span>
				</el-button>
				<el-button @click="addDataLog" class="new_btn" operation-button round :disabled="$store.getters.permissionsStr('新增')">
					<span>新增</span>
				</el-button>
				<el-button @click="delMoreData" class="del_btn" round :disabled="$store.getters.permissionsStr('删除') || !tableDataSelectArr.length">
					<span>删除</span>
				</el-button> -->
			</div>
		</div>

		<div class="content-flex-pages" style="background: #fff">
			<div class="lac_pages_content">
				<div class="pages_view" v-for="(item, index) in dataList" :key="index" @click="goDetail(item)">
					<div class="pages_view_top">
						<el-avatar :size="35">
							<img :src="item.img" />
						</el-avatar>
						<span>{{item.name}}</span>
						<span>{{item.sex ==1 ? '男' : '女'}}</span>
						<span>{{item.old}}岁</span>
					</div>
					<div class="pages_view_cen">
						<div class="pages_view_cen_con">
							<div>
								<i class="iconfont icona-1">	</i>
								测试次数
							</div>
							<div style="text-align: right;">
							 <b>{{item.value1}}</b></br> <span>(次)</span>
							</div>
						</div>
						<div class="pages_view_cen_con">
							<div>
								<i class="iconfont icona-2">	</i>
								血乳酸阀值
							</div>
							<div style="text-align: right;">
								<b>{{item.value2}}</b></br><span>(mmol/L)</span>
							</div>
						</div>
					</div>

						<div class="pages_view_cen">
						<div class="pages_view_cen_con">
							<div>
								<i class="iconfont icona-2">	</i>
								血乳酸最高值
							</div>
							<div style="text-align: right;">
								<b>{{item.value3}}</b></br><span>(mmol/L)</span>
							</div>
						</div>
						<div class="pages_view_cen_con">
							<div>
								<i class="iconfont icona-2">	</i>
								血乳酸清除平均速度          
							</div>
							<div style="text-align: right;">
								<b>{{item.value4}}</b></br><span>(mmol/L/min)</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<el-pagination
					:current-page.sync="currentPage"
					:page-size="10"
					:total="tableTotal"
					@current-change="handleCurrentChange"
					class="table-pagination"
					layout="total, prev, pager, next , jumper"
				></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentPage: 1,
			tableTotal: 4,
			date: [],
			deparmentId: '',
			inputValue: '',
			departmentOptions: [],
			pickerOptions: {},
			dataList: [
				{
					name: '张某某',
					sex: 1,
					old: 17,
					height: 173,
					weight: 63.3,
					sport_team: '体育训练基地/游泳男队',
					department_name: '游泳',
					img: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
					value1: '2',
					value2: '7.49',
					value3: '15.5',
					value4: '0.25',
				},
				{
					name: '胡XX',
					sex: 2,
					old: 18,
					height: 175,
					weight: 55,
					sport_team: '体育训练基地/游泳女队',
					department_name: '游泳',
					img: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png',
					value1: '2',
					value2: '26',
					value3: '27',
					value4: '28',
					
				},
				{
					name: '李某某',
					sex: 2,
					old: 19,
					height: 160,
					weight: 50,
					sport_team: '体育训练基地/游泳女队',
					department_name: '游泳',
					img: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png',
					value1: '1',
					value2: '36',
					value3: '37',
					value4: '38',


				},
				{
					name: '王某某',
					sex: 2,
					old: 20,
					height: 168,
					weight: 58,
					sport_team: '体育训练基地/游泳女队',
					department_name: '游泳',
					img: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png',
					value1: '2',
					value2: '46',
					value3: '47',
					value4: '48',
				}
			]
		}
	},
	methods: {
		create() {},
		searchCreate() {},
		handleCurrentChange() {},
		goDetail(item) {
			this.$router.push({path: '/textItem/lactateTextDetail', query: {
				img: item.img,
				name: item.name,
				sex: item.sex,
				old: item.old,
				height: item.height,
				weight: item.weight,
				sport_team: item.sport_team,
				department_name: item.department_name
			}})
		}
	},
}
</script>

<style lang="scss" scoped>
.lactate_text_view {
	.child-top {
		margin-top: 10px;
		border-radius: 5px;
		.input-from {
			float: left;
			min-width: 1100px;
			margin-bottom: 0;

			.picker_date {
				float: left;
				margin-top: 20px;
			}

			& > .el-select {
				width: 150px;
				float: left;
				margin-top: 20px;
			}

			.el-select,
			.el-input {
				margin-top: 20px;
			}

			.el-input.el-input--suffix.left-child-input {
				float: left;
				width: 200px;
			}

			.el-cascader {
				margin-top: 18px;
				min-width: 200px;
			}

			.searchBtn {
				margin-top: 20px;
			}
		}

		.operation-button {
			// float: left;
			margin-bottom: 10px;
		}
	}
	.lac_pages_content {
		display: flex;
		flex-wrap: wrap;
	}
	.content-flex-pages {
		padding: 20px;
	}
	.pages_view {
		width: 32%;
		border-radius: 10px;
		overflow: hidden;
		border: 1px solid #DDDDDD;
		margin-right: 1%;
		margin-bottom: 20px;
		&:nth-child(3n) {
			margin-right: 0;
		}
		&:hover {
			cursor: pointer;
			border: 1px solid #0055E9;
		}
		.pages_view_top {
			display: flex;
			align-items: center;
			padding: 10px 0;
			font-size: 16px;
			background: #f7f7f7;
			span {
				margin: 0 10px 0 10px;
			}
		}
		.pages_view_cen {
			margin: 0 15px;
			padding: 10px 0;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #ccc;
			&:last-child {
				border-bottom: none;
			}

			.pages_view_cen_con {
				width: 45%;
				display: flex;
				justify-content: space-between;
				color: #333333;
				font-size: 16px;
				i {
					color: #0055E9;
					font-size: 17px;
					margin-right: 5px;
				}
				span {
					color: #999;
				}
			}
		}
	}
}
</style>
