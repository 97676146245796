import { render, staticRenderFns } from "./lactateText.vue?vue&type=template&id=649bcb29&scoped=true"
import script from "./lactateText.vue?vue&type=script&lang=js"
export * from "./lactateText.vue?vue&type=script&lang=js"
import style0 from "./lactateText.vue?vue&type=style&index=0&id=649bcb29&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649bcb29",
  null
  
)

export default component.exports